import iAttribute, {
  AttributeForSections,
  AttributeTypes,
} from '../../../types/attribute/iAttribute';
import AttributeDiv from '../AttributeDiv';
import { InlineFlex } from '../../frameWork/Flex';
import AttributeValueInput from './AttributeValueInput';
import iAttributeSet from '../../../types/attribute/iAttributeSet';
import { Link } from 'react-router-dom';
import { URL_SETTINGS_ATTRIBUTE_SET_DETAILS } from '../../../helpers/UrlMap';
import React from 'react';
import * as _ from 'lodash';
import { iErrorMap } from '../../form/FormError';

export enum AttrSettingsFieldNames {
  options = 'options',
  forSectionsMap = 'forSectionsMap',
  editLogic = 'editLogic',
  buildSizeFieldName = 'buildSizeFieldName',
  aliasSourceAttrConf = 'aliasSourceAttrConf',
  productSelector = 'productSelector',
  emailBtnConf = 'emailBtnConf',
}

export enum WhenActions {
  AttrValueProvided = 'AttrValueProvided',
  AttrValueIs = 'AttrValueIs',
}

export enum ThenActions {
  AttrIsRequired = 'AttrIsRequired',
  AttrLimitValues = 'AttrLimitValues',
  AttrValueWillBe = 'AttrValueWillBe',
}

export type iEditLogicWhen = {
  type: WhenActions | null;
  attrValues?: string[];
  attribute?: iAttribute;
};
export type iEditLogicThen = {
  type: ThenActions | null;
  attrValues?: string[];
};
export type iEditLogic = {
  id?: string;
  when?: iEditLogicWhen[];
  then?: iEditLogicThen;
};

export type iAttributeForSection = {
  isRequired?: boolean;
  isEditable?: boolean;
  isReadable?: boolean;
};
export type iAttributeForSectionSettingsMap = {
  [key: string]: iAttributeForSection;
};
export type iAttrProductSelector = {
  productTypeIds?: string[];
  showSelected?: boolean;
  showSelectedPic?: boolean;
};
// this is the object to contain this attribute is readable, editable or required for different stage of the build job
const getForActionObj = (
  attribute?: iAttribute,
): iAttributeForSectionSettingsMap | null => {
  const settings = attribute?.settings || {};
  return AttrSettingsFieldNames.forSectionsMap in settings
    ? settings[AttrSettingsFieldNames.forSectionsMap]
    : null;
};

const getEditLogic = (attribute?: iAttribute): iEditLogic[] => {
  const settings = attribute?.settings || {};
  return AttrSettingsFieldNames.editLogic in settings
    ? settings[AttrSettingsFieldNames.editLogic]
    : [];
};

const getDropdownOptions = (attribute?: iAttribute): string[] => {
  const settings = attribute?.settings || {};
  return _.uniq(
    (AttrSettingsFieldNames.options in settings
      ? settings[AttrSettingsFieldNames.options]
      : []
    )
      .map((option: string) => `${option || ''}`.trim())
      .filter((option: string) => option !== ''),
  );
};

const getBuildSizeFieldName = (attribute?: iAttribute): string => {
  const settings = attribute?.settings || {};
  return AttrSettingsFieldNames.buildSizeFieldName in settings
    ? settings[AttrSettingsFieldNames.buildSizeFieldName]
    : '';
};

export type iAttrAliasTargetAttrConf = {
  attributeId?: string;
  attributeSetCode?: string;
};
const getAliasTargetAttrConf = (
  attribute?: iAttribute,
): iAttrAliasTargetAttrConf => {
  const settings = attribute?.settings || {};
  return AttrSettingsFieldNames.aliasSourceAttrConf in settings
    ? settings[AttrSettingsFieldNames.aliasSourceAttrConf]
    : {};
};

const formAliasTargetAttrConf = (
  attribute?: iAttribute,
  newSettings?: iAttrAliasTargetAttrConf,
): {
  [AttrSettingsFieldNames.aliasSourceAttrConf]: iAttrAliasTargetAttrConf;
} => {
  const config = getAliasTargetAttrConf(attribute);
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    [AttrSettingsFieldNames.aliasSourceAttrConf]:
      !config && !newSettings
        ? null
        : {
            ...(config || {}),
            ...(newSettings || {}),
          },
  };
};

const getProductSelectorOptions = (
  attribute?: iAttribute,
): iAttrProductSelector => {
  const settings = attribute?.settings || {};
  return AttrSettingsFieldNames.productSelector in settings
    ? settings[AttrSettingsFieldNames.productSelector]
    : {};
};

const formProductSelectorOptions = (
  attribute?: iAttribute,
  newSettings?: iAttrProductSelector,
): { [AttrSettingsFieldNames.productSelector]: iAttrProductSelector } => {
  const options = getProductSelectorOptions(attribute);
  return {
    [AttrSettingsFieldNames.productSelector]: {
      ...options,
      ...newSettings,
    },
  };
};

const isAttributeReadableForSection = (
  attribute: iAttribute,
  forAction: AttributeForSections,
): boolean => {
  const forSectionsObj = getForActionObj(attribute) || {};
  return (
    forAction in forSectionsObj && forSectionsObj[forAction].isReadable === true
  );
};

const isAttributeEditableForSection = (
  attribute: iAttribute,
  forAction: AttributeForSections,
): boolean => {
  const forSectionsObj = getForActionObj(attribute) || {};
  return (
    forAction in forSectionsObj && forSectionsObj[forAction].isEditable === true
  );
};

const isAttributeRequiredForSection = (
  attribute: iAttribute,
  forAction: AttributeForSections,
): boolean => {
  const forSectionsObj = getForActionObj(attribute) || {};
  return (
    forAction in forSectionsObj && forSectionsObj[forAction].isRequired === true
  );
};

const filterReadableAttrsForSection = (
  attributes: iAttribute[],
  forAction: AttributeForSections,
): iAttribute[] => {
  return attributes.filter((attribute) => {
    return isAttributeReadableForSection(attribute, forAction);
  });
};

const isAttributeEditable = (attribute?: iAttribute) => {
  const forSectionsObj = getForActionObj(attribute) || {};
  return (
    Object.values(forSectionsObj).filter(
      (item: iAttributeForSection) => item.isEditable === true,
    ).length > 0
  );
};

const whenActionOptions = {
  [WhenActions.AttrValueProvided]: {
    display: (
      <>
        <b>Another attribute has got value</b>
        <div>
          <small>When another attribute has got value.</small>
        </div>
      </>
    ),
    label: WhenActions.AttrValueProvided,
    value: WhenActions.AttrValueProvided,
  },
  [WhenActions.AttrValueIs]: {
    display: (
      <>
        <b>Another attribute has value and the value is</b>
        <div>
          <small>
            Another attribute has value and the value is the provided value:
          </small>
        </div>
      </>
    ),
    label: WhenActions.AttrValueIs,
    value: WhenActions.AttrValueIs,
  },
};

const thenActionOptions = {
  [ThenActions.AttrIsRequired]: {
    display: (
      <>
        <b>Attribute is required</b>
        <div>
          <small>Then the current attribute will be required.</small>
        </div>
      </>
    ),
    label: ThenActions.AttrIsRequired,
    value: ThenActions.AttrIsRequired,
  },
  [ThenActions.AttrLimitValues]: {
    display: (
      <>
        <b>Attribute values limited</b>
        <div>
          <small>Attribute values will be limited to provided:</small>
        </div>
      </>
    ),
    label: ThenActions.AttrLimitValues,
    value: ThenActions.AttrLimitValues,
  },
  [ThenActions.AttrValueWillBe]: {
    display: (
      <>
        <b>Attribute values will be, when creating</b>
        <div>
          <small>
            Attribute value will be the provided value, when creating:
          </small>
        </div>
      </>
    ),
    label: ThenActions.AttrValueWillBe,
    value: ThenActions.AttrValueWillBe,
  },
};

const translateWhenLogicToHumanReadable = (whens: iEditLogicWhen[]) => {
  if (whens.length <= 0) {
    return null;
  }

  return (
    <>
      {whens.map((when, i) => {
        const values = when.attrValues || [];
        return (
          <InlineFlex key={i} className={'gap-025 align-items-center'}>
            <b>
              <AttributeDiv id={when.attribute?.id || ''} />
            </b>
            {when.type === WhenActions.AttrValueProvided ? (
              <div>has value</div>
            ) : when.type === WhenActions.AttrValueIs ? (
              <div>has value and the value is</div>
            ) : null}
            {values.length <= 0
              ? null
              : values.map((value, valIndex) => {
                  if (!when.attribute) {
                    return null;
                  }
                  return (
                    <AttributeValueInput
                      key={valIndex}
                      attribute={when.attribute}
                      showLabel={false}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      attributeValue={{
                        value: `${value || ''}`,
                      }}
                      attributeSection={null}
                      isDisabled={true}
                      onChange={() => null}
                    />
                  );
                })}
          </InlineFlex>
        );
      })}
    </>
  );
};

const translateThenLogicToHumanReadable = (
  attribute?: iAttribute,
  then?: iEditLogicThen | null,
) => {
  if (!then) {
    return null;
  }
  const values = then.attrValues || [];
  return (
    <InlineFlex className={'gap-025 align-items-center'}>
      <b>Current Attribute</b>

      {then.type === ThenActions.AttrIsRequired ? (
        <div>is required when editing.</div>
      ) : then.type === ThenActions.AttrLimitValues ? (
        <div>will be limited to: </div>
      ) : then.type === ThenActions.AttrValueWillBe ? (
        <div>the value populated by default, when creating: </div>
      ) : null}

      {values.length <= 0
        ? null
        : values.map((value, valIndex) => {
            if (!attribute) {
              return null;
            }
            return (
              <AttributeValueInput
                key={valIndex}
                attribute={attribute}
                showLabel={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                attributeValue={{
                  value: `${value || ''}`,
                }}
                attributeSection={null}
                isDisabled={true}
                onChange={() => null}
              />
            );
          })}
    </InlineFlex>
  );
};

const getAttributeSetLink = (attributeSet?: iAttributeSet | null) => {
  const idStr = `${attributeSet?.id || ''}`.trim();
  const nameStr = `${attributeSet?.name || ''}`.trim();
  if (idStr === '') {
    return nameStr;
  }
  return (
    <Link to={URL_SETTINGS_ATTRIBUTE_SET_DETAILS.replace(':id', idStr)}>
      {nameStr}
    </Link>
  );
};

const validateSettingsPreSave = (attribute?: iAttribute): iErrorMap => {
  const options = getDropdownOptions(attribute);
  if (attribute?.type === AttributeTypes.DROPDOWN && options.length <= 0) {
    return {
      [AttrSettingsFieldNames.options]: ['Options are required'],
    };
  }
  if (
    attribute?.type === AttributeTypes.BUIlD_SIZE_CHANGER &&
    getBuildSizeFieldName(attribute).trim() === ''
  ) {
    return {
      [AttrSettingsFieldNames.buildSizeFieldName]: ['Field is required'],
    };
  }
  const targetAttribute = getAliasTargetAttrConf(attribute);
  if (
    attribute?.type === AttributeTypes.ATTRIBUTE_ALIAS &&
    (`${targetAttribute?.attributeId || ''}`.trim() === '' ||
      `${targetAttribute?.attributeSetCode || ''}`.trim() === '')
  ) {
    return {
      [AttrSettingsFieldNames.aliasSourceAttrConf]: [
        'Source Attribute is required',
      ],
    };
  }
  return {};
};

export type iAttrEmailBtnConf = {
  emailTemplateId?: string;
  to?: string[];
  cc?: string[];
  bcc?: string[];
};
const getEmailBtnConf = (attribute?: iAttribute): iAttrEmailBtnConf => {
  const settings = attribute?.settings || {};
  return AttrSettingsFieldNames.emailBtnConf in settings
    ? settings[AttrSettingsFieldNames.emailBtnConf]
    : {};
};

const formEmailBtnConf = (
  attribute?: iAttribute,
  newSettings?: iAttrEmailBtnConf,
): {
  [AttrSettingsFieldNames.emailBtnConf]: iAttrEmailBtnConf;
} => {
  const config = getEmailBtnConf(attribute);
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    [AttrSettingsFieldNames.emailBtnConf]:
      !config && !newSettings
        ? null
        : {
            ...(config || {}),
            ...(newSettings || {}),
          },
  };
};

const AttributeSettingsHelper = {
  getForActionObj,
  getEditLogic,
  filterReadableAttrsForSection,
  isAttributeRequiredForSection,
  isAttributeEditableForSection,
  isAttributeReadableForSection,
  isAttributeEditable,
  thenActionOptions,
  whenActionOptions,
  translateWhenLogicToHumanReadable,
  translateThenLogicToHumanReadable,
  getAttributeSetLink,
  getBuildSizeFieldName,
  getProductSelectorOptions,
  formProductSelectorOptions,
  getDropdownOptions,
  validateSettingsPreSave,
  getAliasTargetAttrConf,
  formAliasTargetAttrConf,
  getEmailBtnConf,
  formEmailBtnConf,
};

export default AttributeSettingsHelper;
