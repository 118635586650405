import iBuild from '../../../../types/build/iBuild';
import DetailsPanelWithSubMenuPanel from '../../../layout/DetailsPanelWithSubMenuPanel';
import BuildDetailsColoursHelper from './BuildDetailsColoursHelper';
import { AttributeForSections } from '../../../../types/attribute/iAttribute';

export type iBuildDetailsColoursPanel = {
  build: iBuild;
  testId?: string;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};
const BuildDetailsColoursPanel = ({
  build,
  onBuildUpdated,
  testId,
  isDisabled = false,
}: iBuildDetailsColoursPanel) => {
  const testIdStr = `${testId || ''}-buildDetails-prelim`;

  return (
    <DetailsPanelWithSubMenuPanel
      testId={testIdStr}
      menuItemsMap={BuildDetailsColoursHelper.getMenuItemsMap({
        onBuildUpdated,
        build,
        isDisabled,
        attributeSection: AttributeForSections.COLOURS,
      })}
    />
  );
};

export default BuildDetailsColoursPanel;
