import React, { useState, useCallback } from 'react';
import PDFEditorHelper from './PDFEditorHelper';
import PDFEditorEditView from './components/PDFEditorEditView';
import { PDFViewer } from '@react-pdf/renderer';
import styled from 'styled-components';
import iPDFData, { defaultPDFData } from './iPDFData';

const Wrapper = styled.div`
  height: 100vh; /* Full height container */
  display: flex;
  .edit-view {
    background-color: #eee;
    flex-shrink: 0;
    transition: width 0.2s ease;
    width: 100%;
    height: 100%;
  }
  &.with-preview {
    .edit-view {
      width: 45%;
    }
  }

  .preview-wrapper {
    flex: 1;
    overflow: hidden;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
`;

type iPDFEditor = {
  data?: iPDFData | null;
  testId?: string;
  className?: string;
  onChanged: (changedData: iPDFData) => void;
};

const PDFEditor = ({ testId, data, className, onChanged }: iPDFEditor) => {
  const componentName = 'pdfEditor';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [templateData, setTemplateData] = useState<iPDFData>(
    data || defaultPDFData,
  );

  const handleDataChange = useCallback(
    (chg: iPDFData) => {
      setTemplateData(chg);
      onChanged(chg);
    },
    [onChanged],
  );

  return (
    <Wrapper
      className={`${componentName} ${className || ''} with-preview `}
      data-testid={testIdStr}
    >
      <PDFEditorEditView
        className={'edit-view'}
        data={templateData}
        onChanged={handleDataChange}
      />
      <div className="preview-wrapper">
        <PDFViewer>
          {PDFEditorHelper.convertDataToPDFComponent(templateData)}
        </PDFViewer>
      </div>
    </Wrapper>
  );
};

export default PDFEditor;
