import ProtectedPage from '../../layouts/ProtectedPage';
import DeveloperGuidesList from '../../components/developerGuides/DeveloperGuidesList';

const DeveloperGuidesPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `DeveloperGuides`,
      }}
    >
      {(headerProps) => (
        <DeveloperGuidesList allowDelete headerProps={headerProps} />
      )}
    </ProtectedPage>
  );
};

export default DeveloperGuidesPage;
