import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import React, { useEffect } from 'react';
import EmailTemplateService from '../../services/email/EmailTemplateService';
import Toaster, {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
} from '../common/Toaster';
import Spinner from '../frameWork/Spinner';
import Flex from '../frameWork/Flex';
import SectionMessage from '../frameWork/SectionMessage';
import styled from 'styled-components';
import tokens from '../frameWork/Tokens';
import TextField from '../frameWork/TextField';

const Wrapper = styled.div`
  .placeholder-row {
    padding: ${tokens('space.100', '0.25rem')};
    &:hover {
      background-color: ${tokens(
        'color.background.accent.gray.subtlest',
        '#f6f7f8',
      )};
    }
  }
`;

export type iEmailPlaceHoldersPopupBtn = Omit<iPopupBtn, 'titleId'>;

const EmailPlaceHoldersPopupBtn = (props: iEmailPlaceHoldersPopupBtn) => {
  const ComponentName = 'EmailPlaceHoldersPopupBtn';
  const testIdStr = `${ComponentName}-${props.testId || ''}`;
  const [placeholders, setPlaceholders] = React.useState<string[]>([]);
  const [searchStr, setSearchStr] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    let isCancel = false;
    EmailTemplateService.getPlaceHolders()
      .then((resp) => {
        if (isCancel) {
          return;
        }
        setPlaceholders(resp.sort());
      })
      .catch((err) => {
        if (isCancel) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCancel) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCancel = true;
    };
  }, []);

  const copyToClipboard = async (valueToCopy: string) => {
    try {
      await navigator.clipboard.writeText(valueToCopy);
      Toaster.showToast('Copied.', TOAST_TYPE_SUCCESS);
    } catch (err) {
      Toaster.showToast('Failed to copy to clipboard.', TOAST_TYPE_ERROR);
    }
  };

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    setIsLoading(false);
    setModelShowing(false);
    props.onClose && props.onClose(setModelShowing);
  };

  return (
    <PopupBtn
      titleId={ComponentName}
      {...props}
      modalProps={(setModelShowing, isDisabled) => ({
        ...(props.modalProps && props.modalProps(setModelShowing, isDisabled)),
        title: 'The list of place holders you can use:',
        shouldScrollInViewport: true,
        onClose: () => handleClose(setModelShowing),
        body: (
          <Wrapper>
            <SectionMessage
              title="The place holders are case sensitive."
              allowClose={false}
            >
              You can click on the place holders to copy them to the clipboard.
            </SectionMessage>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <TextField
                  placeholder={'search...'}
                  onChange={(e) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    setSearchStr(e.target.value || '');
                  }}
                />
                {placeholders
                  .filter((placeholder) =>
                    placeholder
                      .trim()
                      .toLowerCase()
                      .includes(searchStr.trim().toLowerCase()),
                  )
                  .map((placeholder) => {
                    return (
                      <Flex
                        key={placeholder}
                        className={'cursor-pointer placeholder-row'}
                        onClick={() => copyToClipboard(`{{${placeholder}}}`)}
                      >
                        {'{{'}
                        {placeholder}
                        {'}}'}
                      </Flex>
                    );
                  })}
              </>
            )}
          </Wrapper>
        ),
        footer: getFooterWithBtns({
          actionBtnProps: {
            testId: `${testIdStr}-closeBtn`,
            appearance: 'default',
            isLoading,
            iconBefore: Icons.CrossIcon,
            btnText: 'Close',
            onClick: () => handleClose(setModelShowing),
          },
          cancelBtn: <></>,
        }),
      })}
    />
  );
};

export default EmailPlaceHoldersPopupBtn;
