import ServiceHelper from '../helper/ServiceHelper';
import iDeveloperGuides from '../../types/developerGuide/iDeveloperGuide';

const endPoint = '/developerGuide';
const DeveloperGuidesService = {
  getAll: ServiceHelper.getPaginatedAll<iDeveloperGuides>(endPoint),
  get: ServiceHelper.getById<iDeveloperGuides>(endPoint),
  create: ServiceHelper.create<iDeveloperGuides>(endPoint),
  update: ServiceHelper.updateById<iDeveloperGuides>(endPoint),
  deactivate: ServiceHelper.deactivateById<iDeveloperGuides>(endPoint),
};

export default DeveloperGuidesService;
