import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsColoursOverview from './Components/BuildDetailsColoursOverview';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import EntityNames from '../../../../helpers/EntityNames';
import { AttributeForSections } from '../../../../types/attribute/iAttribute';
import { AttributeSetCodes } from '../../../../types/attribute/iAttributeSet';
import AttributeSetInputPanel from '../../../attributeSet/AttributeSetInputPanel';
import { BuildDetailsContractKeys } from '../BuildDetailsContract/BuildDetailsContractHelper';

export enum BuildDetailsColoursKeys {
  OVERVIEW = 'Summary',
  CABINETS = 'Internal - Cabinets',
  INTERNAL_WALL_FLOOR = 'Internal - Wall & Floor',
  INTERNAL_OTHER = 'Internal - Other',
  EXTERNAL = 'External',
  NOTES = 'Notes',
  CHANGES = 'Changes',
  VARIATIONS = 'Variations',
}

const getKey = (
  key: string,
  build: iBuild,
  attributeSection: AttributeForSections,
) => `${attributeSection}-${build.id}-${key}`;

type iGetBuildDetailsColoursMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
  attributeSection: AttributeForSections;
};

const getMenuItemsMap = ({
  build,
  onBuildUpdated,
  isDisabled = false,
  attributeSection,
}: iGetBuildDetailsColoursMenuItemsMap): iMenuItemsMap => {
  const commonProps = {
    attributeSection,
    isDisabled,
    entityId: build.id,
    entityName: EntityNames.Build,
  };
  return {
    [BuildDetailsColoursKeys.OVERVIEW]: {
      name: BuildDetailsColoursKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsColoursKeys.OVERVIEW} />,
      component: (
        <BuildDetailsColoursOverview
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsColoursKeys.CABINETS]: {
      name: BuildDetailsColoursKeys.CABINETS,
      icon: <Icons.QueuesIcon label={BuildDetailsColoursKeys.CABINETS} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.Cabinet,
            AttributeSetCodes.CabinetKitchen,
            AttributeSetCodes.Vanity,
            AttributeSetCodes.Pantry,
            AttributeSetCodes.CabinetPantry,
          ]}
          key={getKey(
            BuildDetailsContractKeys.CABINETS,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsColoursKeys.INTERNAL_WALL_FLOOR]: {
      name: BuildDetailsColoursKeys.INTERNAL_WALL_FLOOR,
      icon: (
        <Icons.AppSwitcherIcon
          label={BuildDetailsColoursKeys.INTERNAL_WALL_FLOOR}
        />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.Flooring,
            AttributeSetCodes.WallTiles,
            AttributeSetCodes.TilingGeneral,
            AttributeSetCodes.Carpet,
            AttributeSetCodes.BorderTiles,
            AttributeSetCodes.Niche,
          ]}
          key={getKey(
            BuildDetailsContractKeys.CABINETS,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsColoursKeys.INTERNAL_OTHER]: {
      name: BuildDetailsColoursKeys.INTERNAL_OTHER,
      icon: (
        <Icons.MediaServicesFilterIcon
          label={BuildDetailsColoursKeys.INTERNAL_OTHER}
        />
      ),
      component: <div>{BuildDetailsColoursKeys.INTERNAL_OTHER}</div>,
    },
    [BuildDetailsColoursKeys.EXTERNAL]: {
      name: BuildDetailsColoursKeys.EXTERNAL,
      icon: (
        <Icons.BitbucketReposIcon label={BuildDetailsColoursKeys.EXTERNAL} />
      ),
      component: <div>{BuildDetailsColoursKeys.EXTERNAL}</div>,
    },
    [BuildDetailsColoursKeys.NOTES]: {
      name: BuildDetailsColoursKeys.NOTES,
      icon: (
        <Icons.MediaServicesAnnotateIcon
          label={BuildDetailsColoursKeys.NOTES}
        />
      ),
      component: <div>{BuildDetailsColoursKeys.NOTES}</div>,
    },
    [BuildDetailsColoursKeys.CHANGES]: {
      name: BuildDetailsColoursKeys.CHANGES,
      icon: (
        <Icons.EditorNumberListIcon label={BuildDetailsColoursKeys.CHANGES} />
      ),
      component: <div>{BuildDetailsColoursKeys.CHANGES}</div>,
    },
    [BuildDetailsColoursKeys.VARIATIONS]: {
      name: BuildDetailsColoursKeys.VARIATIONS,
      icon: (
        <Icons.BitbucketPullrequestsIcon
          label={BuildDetailsColoursKeys.VARIATIONS}
        />
      ),
      component: <div>{BuildDetailsColoursKeys.VARIATIONS}</div>,
    },
  };
};

const BuildDetailsColoursHelper = {
  getMenuItemsMap,
};
export default BuildDetailsColoursHelper;
