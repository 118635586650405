import { iOption } from '../frameWork/Select';
import { OP_LIKE } from '../../services/ServiceHelper';
import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import DeveloperGuideCategoryService from '../../services/developerGuideCategory/DeveloperGuideCategoryService';

export type iDeveloperGuideCategorySelector = iPreloadAsyncSelectProps;
const DeveloperGuideCategorySelector = (
  props: iDeveloperGuideCategorySelector,
) => {
  return (
    <PreloadedAsyncSelector<iDeveloperGuideCategory>
      placeholder={'Select a developer category ...'}
      {...props}
      formatOptionLabel={(data: iOption) => {
        if (props.formatOptionLabel) {
          return props.formatOptionLabel(data);
        }
        const description = `${data.data.description || ''}`.trim();
        return (
          <>
            {data.data.name}
            {data.data.code}
            {description === '' ? null : <small>: {description}</small>}
          </>
        );
      }}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return DeveloperGuideCategoryService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        DeveloperGuideCategoryService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default DeveloperGuideCategorySelector;
