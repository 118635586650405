import iBaseType from '../iBaseType';

export enum OrderPageTemplateCategories {
  StartToLockup = 'start_to_lockup',
  LockupToCompletion = 'lockup_to_completion',
}

type iOrderPageTemplate = iBaseType & {
  name: string;
  description: string;
  categoryCode: string;
  sortOrder: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  templateData: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any | null;
};

export default iOrderPageTemplate;
