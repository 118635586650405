import iAddress from '../types/system/iAddress';

const getFullAddress = (address?: iAddress | null): string => {
  if (!address) {
    return '';
  }
  const suburb = `${address.suburb || ''}`.trim();
  const state = `${address.state || ''}`.trim();
  return [
    address.name || '',
    address.street || '',
    suburb === '' ? '' : `${suburb.toUpperCase()},`,
    state === '' ? '' : `${state.toUpperCase()}`,
    address.postcode,
  ]
    .map((str) => `${str || ''}`.trim())
    .filter((str) => str !== '')
    .join(' ')
    .trim();
};

const AddressHelper = {
  getFullAddress,
};

export default AddressHelper;
