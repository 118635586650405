import iAttribute, {
  AttributeTypes,
} from '../../../types/attribute/iAttribute';
import styled from 'styled-components';
import TextArea from '../../frameWork/TextArea';
import { getErrorProps } from '../../form/FormError';
import { iUseAsForm } from '../../form/EntityEditPanel';
import Toggle from '../../frameWork/Toggle';
import { iConfigParams } from '../../../services/AppService';
import ProductTypeSelector from '../../productType/ProductTypeSelector';
import Select, { iOption, iOptionWithData } from '../../frameWork/Select';
import AttributeSettingsHelper, {
  AttrSettingsFieldNames,
} from './AttributeSettingsHelper';
import { sizeFields } from '../../../types/build/iBuildStyle';
import AttributeSelector from '../AttributeSelector';
import { AttributeSetCodes } from '../../../types/attribute/iAttributeSet';
import EmailTemplateSelector from '../../emailTemplate/EmailTemplateSelector';
import TextField from '../../frameWork/TextField';

const Wrapper = styled.div``;
export type iAttributeSettingsEditPanel = {
  attribute?: iAttribute;
  attributeSetCode: AttributeSetCodes;
  isDisabled?: boolean;
  testId?: string;
  className?: string;
  useAsForm: iUseAsForm;
};
const AttributeSettingsEditPanel = ({
  testId,
  className,
  attribute,
  isDisabled,
  attributeSetCode,
  useAsForm,
}: iAttributeSettingsEditPanel) => {
  const componentName = 'attribute-settings-edit';
  const testIdStr = `${testId || ''}-${componentName}`;

  const getSelectedAttributeType = () => `${attribute?.type || ''}`.trim();

  const getSettingObject = () => {
    return attribute?.settings || {};
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFieldChanged = (newValue: iConfigParams) => {
    useAsForm.onFieldChange('settings', {
      ...getSettingObject(),
      ...newValue,
    });
  };

  const getOptionTextArea = () => {
    const settings = getSettingObject();
    const options = 'options' in settings ? settings.options : [];
    return (
      <TextArea
        label={`Options`}
        testId={'options'}
        isRequired
        isDisabled={isDisabled}
        value={options.join('\n')}
        helperMsg={`Each Option Per Line`}
        {...getErrorProps({
          fieldName: 'options',
          error: useAsForm.errorMap,
        })}
        onChange={(event) => {
          handleFieldChanged({ options: event.target.value.split('\n') });
        }}
      />
    );
  };

  const getProductDropdownSettings = () => {
    const { productTypeIds, showSelected, showSelectedPic } =
      AttributeSettingsHelper.getProductSelectorOptions(attribute);
    return (
      <>
        <ProductTypeSelector
          isMulti
          label={`Product Types`}
          testId={'product-type-selector'}
          onChange={(options: iOption[] | null) => {
            handleFieldChanged(
              AttributeSettingsHelper.formProductSelectorOptions(attribute, {
                productTypeIds: (options || [])
                  .map((opt) => `${opt.value || ''}`)
                  .filter((v) => `${v || ''}`.trim() !== ''),
              }),
            );
          }}
          values={productTypeIds}
          helperMsg={`IsForBuild products with selected types will be selectable. BLANK will allow all isForBuild Products`}
        />
        <Toggle
          label={'Show Product'}
          testId={'show-product'}
          helperMsg={'whether product will be shown, when selected.'}
          isDisabled={isDisabled}
          isChecked={showSelected === true}
          onChange={(event) => {
            handleFieldChanged(
              AttributeSettingsHelper.formProductSelectorOptions(attribute, {
                showSelected: event.target.checked,
                ...(event.target.checked === false
                  ? { showSelectedPic: false }
                  : {}),
              }),
            );
          }}
        />
        {showSelected === true && (
          <Toggle
            label={'Show Product Image'}
            testId={'show-product-img'}
            helperMsg={'whether product image will be shown, when selected.'}
            isDisabled={isDisabled}
            isChecked={showSelectedPic === true}
            onChange={(event) =>
              handleFieldChanged(
                AttributeSettingsHelper.formProductSelectorOptions(attribute, {
                  showSelectedPic: event.target.checked,
                }),
              )
            }
          />
        )}
      </>
    );
  };

  const getBuildSizeFieldOptions = () => {
    const fieldName = AttributeSettingsHelper.getBuildSizeFieldName(attribute);
    return (
      <Select
        label={`Size Field`}
        testId={'size-field'}
        isRequired
        isDisabled={isDisabled}
        value={
          fieldName === '' ? undefined : { label: fieldName, value: fieldName }
        }
        options={sizeFields.map((sizeField) => ({
          label: sizeField,
          value: sizeField,
        }))}
        helperMsg={`Which size field this attribute is for`}
        {...getErrorProps({
          fieldName: AttrSettingsFieldNames.buildSizeFieldName,
          error: useAsForm.errorMap,
        })}
        onChange={(selected) => {
          handleFieldChanged({
            [AttrSettingsFieldNames.buildSizeFieldName]:
              `${selected?.value || ''}`.trim(),
          });
        }}
      />
    );
  };

  const getAliasAttributeTarget = () => {
    const { attributeId } =
      AttributeSettingsHelper.getAliasTargetAttrConf(attribute);
    return (
      <AttributeSelector
        label={`Current Attribute is an alias of:`}
        testId={'target-attribute-selector'}
        isRequired
        isDisabled={isDisabled}
        value={attributeId}
        excludeAttributeSetCodes={[attributeSetCode]}
        {...getErrorProps({
          fieldName: AttrSettingsFieldNames.aliasSourceAttrConf,
          error: useAsForm.errorMap,
        })}
        onChange={(selected: iOptionWithData<iAttribute>) => {
          handleFieldChanged(
            AttributeSettingsHelper.formAliasTargetAttrConf(attribute, {
              attributeId: selected.data?.id || '',
              attributeSetCode: selected.data?.attributeSetCode || '',
            }),
          );
        }}
      />
    );
  };

  const getEmailBtnOptions = () => {
    const { emailTemplateId, to, cc, bcc } =
      AttributeSettingsHelper.getEmailBtnConf(attribute);
    return (
      <>
        <EmailTemplateSelector
          label={`Select an Email Template`}
          testId={'email-template-selector'}
          isRequired
          isDisabled={isDisabled}
          value={emailTemplateId}
          excludeAttributeSetCodes={[attributeSetCode]}
          {...getErrorProps({
            fieldName: AttrSettingsFieldNames.emailBtnConf,
            error: useAsForm.errorMap,
          })}
          onChange={(selected: iOptionWithData<iAttribute>) => {
            handleFieldChanged(
              AttributeSettingsHelper.formEmailBtnConf(attribute, {
                emailTemplateId: selected.data?.id || '',
              }),
            );
          }}
        />
        <TextField
          label={'To'}
          testId={'to'}
          helperMsg={'can be separated by ;'}
          isRequired
          isDisabled={isDisabled}
          value={(to || []).join(';')}
          onChange={(e) =>
            handleFieldChanged(
              AttributeSettingsHelper.formEmailBtnConf(attribute, {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                to: `${e.target.value || ''}`.split(';'),
              }),
            )
          }
        />
        <TextField
          label={'CC'}
          testId={'cc'}
          helperMsg={'can be separated by ;'}
          isRequired
          isDisabled={isDisabled}
          value={(cc || []).join(';')}
          onChange={(e) =>
            handleFieldChanged(
              AttributeSettingsHelper.formEmailBtnConf(attribute, {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                cc: `${e.target.value || ''}`.split(';'),
              }),
            )
          }
        />
        <TextField
          label={'BCC'}
          testId={'bcc'}
          helperMsg={'can be separated by ;'}
          isRequired
          isDisabled={isDisabled}
          value={(bcc || []).join(';')}
          onChange={(e) =>
            handleFieldChanged(
              AttributeSettingsHelper.formEmailBtnConf(attribute, {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                bcc: `${e.target.value || ''}`.split(';'),
              }),
            )
          }
        />
      </>
    );
  };

  const getContent = () => {
    switch (getSelectedAttributeType()) {
      case AttributeTypes.DROPDOWN: {
        return getOptionTextArea();
      }
      case AttributeTypes.PRODUCT_DROPDOWN: {
        return getProductDropdownSettings();
      }
      case AttributeTypes.BUIlD_SIZE_CHANGER: {
        return getBuildSizeFieldOptions();
      }
      case AttributeTypes.ATTRIBUTE_ALIAS: {
        return getAliasAttributeTarget();
      }
      case AttributeTypes.EMAIL_BTN: {
        return getEmailBtnOptions();
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      {getContent()}
    </Wrapper>
  );
};

export default AttributeSettingsEditPanel;
