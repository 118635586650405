import { useRef } from 'react';

import DefaultEmailEditor, {
  Editor,
  EditorRef,
  EmailEditorProps,
} from 'react-email-editor';
import styled from 'styled-components';

export type iEditor = Editor;
export type iEmailEditor = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  designData?: any;
  testId?: string;
  className?: string;
  getEditor?: (editor: iEditor) => void;
  onChanged?: (data: object, html: string) => void;
  minHeight?: number | string;
  options?: EmailEditorProps['options'];
};

const Wrapper = styled.div``;
const EmailEditor = ({
  getEditor,
  designData,
  testId,
  className,
  minHeight,
  onChanged,
  options,
}: iEmailEditor) => {
  const componentName = 'EmailEditor';
  const testIdStr = `${componentName}-${testId || ''}`;
  const emailEditorRef = useRef<EditorRef>(null);

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    if (designData) {
      unlayer.loadDesign(designData);
    }
    if (getEditor) {
      getEditor(unlayer as iEditor);
    }

    unlayer.addEventListener('design:updated', () => {
      // // Design is updated by the user
      // const type = data.type; // body, row, content
      // const item = data.item;
      // const changes = data.changes;
      // console.log('design:updated', type, item, changes);
      unlayer.exportHtml((data) => {
        const { design, html } = data;
        onChanged && onChanged(design, html);
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // unlayer.addEventListener('image:uploaded', (imageData: any) => {
    //   // const image = imageData.image;
    //   unlayer.exportHtml((data) => {
    //     const { design, html } = data;
    //     onChanged && onChanged(design, html);
    //   });
    // });
  };
  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <DefaultEmailEditor
        ref={emailEditorRef}
        onReady={onReady}
        minHeight={minHeight}
        options={{
          displayMode: 'email',
          ...options,
        }}
      />
    </Wrapper>
  );
};

export default EmailEditor;
