import styled from 'styled-components';
import iEmailTemplate from '../../types/email/iEmailTemplate';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '../frameWork/DropdownMenu';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import Lozenge from '../frameWork/Lozenge';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import React, { useEffect, useState } from 'react';
import EmailEditor from '../EmailEditor';
import EmailTemplateService from '../../services/email/EmailTemplateService';
import PopupBtn from '../common/PopupBtn';
import TextField from '../frameWork/TextField';
import { getFooterWithBtns } from '../common/PopupModal';
import { getErrorProps, iErrorMap } from '../form/FormError';
import tokens from '../frameWork/Tokens';
import Flex from '../frameWork/Flex';
import InlineEdit from '../frameWork/InlineEdit';
import EmailPlaceHoldersPopupBtn from './EmailPlaceHoldersPopupBtn';
import Button from '@atlaskit/button';
import Spinner from '../frameWork/Spinner';

const Wrapper = styled.div`
  .subject-input {
    margin-bottom: ${tokens('space.200', '1rem')};
  }
`;
export type iEmailTemplateDetailsPanel = iComponentWithPageHeader & {
  emailTemplate: iEmailTemplate;
  allowDelete?: boolean;
  onSaved?: (saved: iEmailTemplate) => void;
  onDeleted?: () => void;
  testId?: string;
  className?: string;
};
const EmailTemplateDetailsPanel = ({
  emailTemplate,
  testId,
  className,
  allowDelete = false,
  onSaved,
  onDeleted,
  headerProps,
  ...props
}: iEmailTemplateDetailsPanel) => {
  const componentName = 'EmailTemplateDetails';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [errors, setErrors] = useState<iErrorMap | null>(null);
  const [editingTemplate, setEditingTemplate] = useState({ ...emailTemplate });
  const [placeHolders, setPlaceHolders] = useState<string[]>([]);

  useEffect(() => {
    let isCanceled = false;

    setIsLoading(true);
    EmailTemplateService.getPlaceHolders()
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setPlaceHolders(resp);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, []);

  const preCheck = () => {
    const errors: iErrorMap = {};
    if (`${editingTemplate.name || ''}`.trim() === '') {
      errors.name = 'Name is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSave = (updatingData: any, triggerOnSaved = true) => {
    if (!preCheck()) {
      return;
    }
    setIsSaving(true);
    EmailTemplateService.update(emailTemplate.id, updatingData)
      .then((saved) => {
        setIsSaving(false);
        setEditingTemplate(saved);
        if (triggerOnSaved === true && onSaved) {
          onSaved(saved);
        }
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const getMergeTagGroupName = (placeHolder: string) => {
    const groupName = placeHolder.toUpperCase().trim();
    if (groupName.includes('CUSTOMER_')) {
      return 'Customer';
    }
    if (groupName.includes('CURRENT_COMPANY_')) {
      return 'Current Company';
    }
    if (groupName.includes('CURRENT_USER_')) {
      return 'Current User';
    }
    if (groupName.includes('BUILD_')) {
      return 'Build Job';
    }
    return '';
  };

  const getMergeTags = () => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    return placeHolders.reduce((map: any, placeHolder) => {
      const groupName = getMergeTagGroupName(placeHolder);
      const newItem = {
        [placeHolder]: {
          name: placeHolder,
          value: `{{${placeHolder}}}`,
        },
      };
      if (groupName === '') {
        return {
          ...map,
          ...newItem,
        };
      }

      const groupTag = groupName in map ? map[groupName] : {};
      return {
        ...map,
        [groupName]: {
          ...groupTag,
          name: groupName,
          mergeTags: {
            ...groupTag.mergeTags,
            ...newItem,
          },
        },
      };
    }, {});
  };

  const getActions = () => {
    return (
      <Flex className={'gap-1 align-items-center'}>
        <EmailPlaceHoldersPopupBtn
          renderBtn={(onClick) => {
            return (
              <Button
                onClick={onClick}
                iconBefore={
                  <Icons.InformationIcon
                    label={'Show me all the placeholders'}
                  />
                }
              >
                Show All Placeholders
              </Button>
            );
          }}
        />
        <DropdownMenu
          placement={'bottom-end'}
          shouldRenderToParent
          trigger={({ triggerRef, ...props }) => {
            return (
              <IconButton
                {...props}
                icon={Icons.MoreIcon}
                ref={triggerRef}
                label={''}
                testId={`options-btn-${testIdStr}`}
                isSelected={false}
              />
            );
          }}
        >
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => setShowRenamePopup(true)}
              testId={`rename-popup-btn-${testIdStr}`}
            >
              Rename
            </DropdownItem>
            {allowDelete !== true ? null : (
              <DropdownItem onClick={() => setShowDeletePopup(true)}>
                Delete
              </DropdownItem>
            )}
          </DropdownItemGroup>
        </DropdownMenu>
        <PopupBtn
          titleId={`rename-${emailTemplate.id}`}
          renderBtn={() => null}
          forceShown={showRenamePopup}
          modalProps={(setModelShowing) => ({
            title: (
              <>
                Are you about to rename <Lozenge>{emailTemplate.name}</Lozenge>
              </>
            ),
            shouldScrollInViewport: true,
            body: (
              <>
                <TextField
                  isRequired
                  value={editingTemplate.name || ''}
                  label={'New name'}
                  placeholder={'The name of the email template'}
                  testId={`rename-input-${testIdStr}`}
                  onChange={(event) => {
                    setEditingTemplate({
                      ...emailTemplate,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      name: event.target.value,
                    });
                  }}
                  {...getErrorProps({
                    fieldName: 'newName',
                    error: errors,
                  })}
                />
              </>
            ),
            footer: getFooterWithBtns({
              actionBtnProps: {
                testId: `rename-popup-saveBtn-${testId || ''}`,
                isLoading: isSaving,
                iconBefore: Icons.SendIcon,
                isDisabled: `${editingTemplate.name || ''}`.trim() === '',
                btnText: 'Save',
                onClick: () => handleSave({ name: editingTemplate.name }),
              },
              cancelBtnProps: {
                testId: `rename-popup-cancelBtn-${testId || ''}`,
                isLoading: isSaving,
                onClick: () => {
                  setEditingTemplate({
                    ...editingTemplate,
                    name: emailTemplate.name,
                  });
                  setModelShowing(false);
                  setShowRenamePopup(false);
                },
              },
            }),
          })}
        />
        {allowDelete !== true ? null : (
          <DeleteConfirmPopupBtn
            onCancel={() => {
              setShowDeletePopup(false);
            }}
            forceShown={showDeletePopup}
            testId={`delete-btn-${emailTemplate.id}`}
            titleId={`delete-btn-${emailTemplate.id}`}
            message={
              <>
                You are about to delete
                <Lozenge>{emailTemplate.name}</Lozenge>.
              </>
            }
            deleteFnc={() => EmailTemplateService.deactivate(emailTemplate.id)}
            renderBtn={() => null}
            onDeleted={() => {
              Toaster.showToast(
                <>
                  <b>
                    <u>{emailTemplate.name}</u>
                  </b>
                  {' Deleted'}
                </>,

                TOAST_TYPE_SUCCESS,
              );
              onDeleted && onDeleted();
            }}
          />
        )}
      </Flex>
    );
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <ComponentWithPageHeader
        {...props}
        headerProps={{
          ...headerProps,
          children: headerProps?.children,
          actions: getActions(),
        }}
      >
        {isLoading === true ? (
          <Spinner testId={`${testIdStr}-loading`} />
        ) : (
          <>
            <InlineEdit
              label={'Subject'}
              value={editingTemplate.subject || ''}
              onConfirm={(newSubject) =>
                handleSave({
                  subject: newSubject,
                })
              }
            />
            <EmailEditor
              onChanged={(data, html) =>
                handleSave(
                  {
                    templateData: data,
                    html,
                  },
                  false,
                )
              }
              designData={editingTemplate.templateData}
              minHeight={`calc(100vh - 12rem)`}
              options={{
                mergeTags: getMergeTags(),
              }}
            />
          </>
        )}
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default EmailTemplateDetailsPanel;
