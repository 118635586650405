import styled from 'styled-components';
import { useRef, useState } from 'react';
import Editor from '@monaco-editor/react';
import iPDFData, { defaultPDFData } from '../iPDFData';
import tokens from '../../frameWork/Tokens';

const Wrapper = styled.div`
  padding-bottom: 1rem;
  //background-color: ${tokens('color.background.accent.gray.subtlest')};
  //overflow: hidden;
  height: 600px;
  border: 1rem ${tokens('color.background.accent.gray.subtlest')} solid;

  .edit-view-content {
    height: 100%;
    .json-editor .monaco-editor {
      width: 100%;
      height: 100%;
    }
  }
`;

type iPDFEditorEditView = {
  data?: iPDFData;
  testId?: string;
  className?: string;
  onChanged: (changedData: iPDFData) => void;
};

const PDFEditorEditView = ({
  data = defaultPDFData,
  testId,
  className,
  onChanged,
}: iPDFEditorEditView) => {
  const componentName = 'PDFEditorEditView';
  const testIdStr = `${testId || ''}-${componentName}`;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [lastValidJson, setLastValidJson] = useState<iPDFData>(data);

  const handleEditorChange = (value: string | undefined) => {
    if (!value) return;

    try {
      const newData = JSON.parse(value);
      setLastValidJson(newData);
      onChanged(newData);
    } catch (e) {
      onChanged(lastValidJson);
    }
  };

  return (
    <Wrapper
      ref={wrapperRef}
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      <div className={`edit-view-content`}>
        <Editor
          height="100%"
          defaultLanguage="json"
          className="json-editor"
          defaultValue={JSON.stringify(data, null, 2)}
          onChange={handleEditorChange}
          options={{
            minimap: { enabled: false },
            formatOnPaste: true,
            formatOnType: true,
            automaticLayout: true,
            folding: true,
            lineNumbers: 'on',
            scrollBeyondLastLine: false,
            wordWrap: 'on',
          }}
        />
      </div>
    </Wrapper>
  );
};

export default PDFEditorEditView;
