import ServiceHelper from '../helper/ServiceHelper';
import iEmailTemplate from '../../types/email/iEmailTemplate';
import AppService, { iConfigParams } from '../AppService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const endPoint = '/emailTemplate';
const EmailTemplateService = {
  getAll: ServiceHelper.getPaginatedAll<iEmailTemplate>(endPoint),
  get: ServiceHelper.getById<iEmailTemplate>(endPoint),
  create: ServiceHelper.create<iEmailTemplate>(endPoint),
  update: ServiceHelper.updateById<iEmailTemplate>(endPoint),
  deactivate: ServiceHelper.deactivateById<iEmailTemplate>(endPoint),
  getPlaceHolders: async (
    params?: iConfigParams,
    config: AxiosRequestConfig = {},
  ) => {
    return AppService.get(`${endPoint}/placeHolder`, params, config).then(
      ({ data }: AxiosResponse) => data,
    );
  },
  getRenderedForBuildId: async (
    id: string,
    buildId: string,
    params?: iConfigParams,
    config: AxiosRequestConfig = {},
  ): Promise<{ body: string; subject: string }> => {
    return AppService.get(
      `${endPoint}/${id}/renderForBuild/${buildId}`,
      params,
      config,
    ).then(({ data }: AxiosResponse) => data);
  },
  getReplaced: async (
    params: { buildId: string; text: string },
    config: AxiosRequestConfig = {},
  ) => {
    return AppService.post(`${endPoint}/replace`, params, config).then(
      ({ data }: AxiosResponse) => data,
    );
  },
};

export default EmailTemplateService;
