import Icons from '../../../frameWork/Icons';
import BuildDetailsContractOverview from './BuildDetailsContractComponents/BuildDetailsContractOverview';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsContractHouse from './BuildDetailsContractComponents/BuildDetailsContractHouse';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import { AttributeSetCodes } from '../../../../types/attribute/iAttributeSet';
import { AttributeForSections } from '../../../../types/attribute/iAttribute';
import AttributeSetInputPanel from '../../../attributeSet/AttributeSetInputPanel';
import EntityNames from '../../../../helpers/EntityNames';
import Heading from '../../../frameWork/Heading';
import PacksForABuild from '../../../buildPack/PacksForABuild';

export enum BuildDetailsContractKeys {
  OVERVIEW = 'Overview',
  HOUSE = 'House',
  DOORS = 'Doors',
  WINDOWS = 'Windows',
  ELECTRICAL = 'Electrical',
  CABINETS = 'Cabinets',
  FLOORING = 'Flooring',
  CONCRETE = 'Concrete',
  PACKS = 'Packs',
  FENCING = 'Fencing',
  BATHROOMS = 'Bathrooms',
  NICHE = 'Niche',
  WALL_TILES = 'Wall Tiles',
  PIERS = 'Piers',
  APPLIANCES = 'Appliances',
  WATER_TANK = 'Water Tank',
  PLUMBING = 'Plumbing',
}

type iGetBuildDetailsContractMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
  attributeSection: AttributeForSections;
};

const getKey = (
  key: string,
  build: iBuild,
  attributeSection: AttributeForSections,
) => `${attributeSection}-${build.id}-${key}`;

const getBuildDetailsContractMenuItemsMap = ({
  build,
  onBuildUpdated,
  attributeSection,
  isDisabled = false,
}: iGetBuildDetailsContractMenuItemsMap): iMenuItemsMap => {
  const commonProps = {
    attributeSection,
    isDisabled,
    entityId: build.id,
    entityName: EntityNames.Build,
  };
  return {
    [BuildDetailsContractKeys.OVERVIEW]: {
      name: BuildDetailsContractKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsContractKeys.OVERVIEW} />,
      component: (
        <BuildDetailsContractOverview
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
          key={getKey(
            BuildDetailsContractKeys.OVERVIEW,
            build,
            attributeSection,
          )}
        />
      ),
    },
    [BuildDetailsContractKeys.HOUSE]: {
      name: BuildDetailsContractKeys.HOUSE,
      icon: <Icons.HomeIcon label={BuildDetailsContractKeys.HOUSE} />,
      component: (
        <BuildDetailsContractHouse
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
          key={getKey(BuildDetailsContractKeys.HOUSE, build, attributeSection)}
        />
      ),
    },
    [BuildDetailsContractKeys.DOORS]: {
      name: BuildDetailsContractKeys.DOORS,
      icon: <Icons.DoorsIcon label={BuildDetailsContractKeys.DOORS} />,
      component: (
        <AttributeSetInputPanel
          key={getKey(BuildDetailsContractKeys.DOORS, build, attributeSection)}
          attributeSetCodes={[
            AttributeSetCodes.FrontDoor,
            AttributeSetCodes.BackDoor,
            AttributeSetCodes.GarageDoor,
            AttributeSetCodes.CavitySlider,
            AttributeSetCodes.InternalDoor,
          ]}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.WINDOWS]: {
      name: BuildDetailsContractKeys.WINDOWS,
      icon: (
        <Icons.EditorLayoutThreeWithSidebarsIcon
          label={BuildDetailsContractKeys.WINDOWS}
        />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Window]}
          key={getKey(
            BuildDetailsContractKeys.WINDOWS,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.ELECTRICAL]: {
      name: BuildDetailsContractKeys.ELECTRICAL,
      icon: (
        <Icons.EditorHintIcon label={BuildDetailsContractKeys.ELECTRICAL} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.ExteriorLight,
            AttributeSetCodes.PowerPoint,
          ]}
          key={getKey(
            BuildDetailsContractKeys.ELECTRICAL,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.CABINETS]: {
      name: BuildDetailsContractKeys.CABINETS,
      icon: <Icons.QueuesIcon label={BuildDetailsContractKeys.CABINETS} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Cabinet]}
          key={getKey(
            BuildDetailsContractKeys.CABINETS,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.FLOORING]: {
      name: BuildDetailsContractKeys.FLOORING,
      icon: (
        <Icons.BitbucketSourceIcon label={BuildDetailsContractKeys.FLOORING} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Flooring]}
          key={getKey(
            BuildDetailsContractKeys.FLOORING,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.CONCRETE]: {
      name: BuildDetailsContractKeys.CONCRETE,
      icon: (
        <Icons.EditorUnderlineIcon label={BuildDetailsContractKeys.CONCRETE} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Concrete]}
          key={getKey(
            BuildDetailsContractKeys.CONCRETE,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.PACKS]: {
      name: BuildDetailsContractKeys.PACKS,
      icon: (
        <Icons.EditorMediaWideIcon label={BuildDetailsContractKeys.PACKS} />
      ),
      component: (
        <PacksForABuild
          buildId={build.id}
          headerProps={{ children: <Heading size={'small'}>Packs</Heading> }}
          allowCreate={!isDisabled}
          allowDelete={!isDisabled}
          key={getKey(BuildDetailsContractKeys.PACKS, build, attributeSection)}
        />
      ),
    },
    [BuildDetailsContractKeys.FENCING]: {
      name: BuildDetailsContractKeys.FENCING,
      icon: <Icons.BoardIcon label={BuildDetailsContractKeys.FENCING} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Fencing]}
          key={getKey(
            BuildDetailsContractKeys.FENCING,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.BATHROOMS]: {
      name: BuildDetailsContractKeys.BATHROOMS,
      icon: <Icons.BathIcon label={BuildDetailsContractKeys.BATHROOMS} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Bathroom]}
          key={getKey(
            BuildDetailsContractKeys.BATHROOMS,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.NICHE]: {
      name: BuildDetailsContractKeys.NICHE,
      icon: (
        <Icons.MediaServicesFilterIcon label={BuildDetailsContractKeys.NICHE} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Niche]}
          key={getKey(BuildDetailsContractKeys.NICHE, build, attributeSection)}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.WALL_TILES]: {
      name: BuildDetailsContractKeys.WALL_TILES,
      icon: (
        <Icons.AppSwitcherIcon label={BuildDetailsContractKeys.WALL_TILES} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.WallTiles]}
          key={getKey(
            BuildDetailsContractKeys.WALL_TILES,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.PIERS]: {
      name: BuildDetailsContractKeys.PIERS,
      icon: <Icons.PiersIcon label={BuildDetailsContractKeys.PIERS} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Pier]}
          key={getKey(BuildDetailsContractKeys.PIERS, build, attributeSection)}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.APPLIANCES]: {
      name: BuildDetailsContractKeys.APPLIANCES,
      icon: (
        <Icons.AppliancesIcon label={BuildDetailsContractKeys.APPLIANCES} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.Appliances]}
          key={getKey(
            BuildDetailsContractKeys.APPLIANCES,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.WATER_TANK]: {
      name: BuildDetailsContractKeys.WATER_TANK,
      icon: <Icons.WaterTankIcon label={BuildDetailsContractKeys.WATER_TANK} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.WaterTank]}
          key={getKey(
            BuildDetailsContractKeys.WATER_TANK,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsContractKeys.PLUMBING]: {
      name: BuildDetailsContractKeys.PLUMBING,
      icon: (
        <Icons.BitbucketCompareIcon label={BuildDetailsContractKeys.PLUMBING} />
      ),
      component: <div>Plumbing for {build.id} is coming soon.</div>,
    },
  };
};

const BuildDetailsContractHelper = {
  getBuildDetailsContractMenuItemsMap,
};
export default BuildDetailsContractHelper;
