import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import iPDFData, { ContentTypes, Section, DocumentPage } from './iPDFData';
import React from 'react';
import { PageSize } from '@react-pdf/types/page';
import TextField from '../frameWork/TextField';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderSection = (section: Section, styles: any) => {
  switch (section.type) {
    case ContentTypes.Text:
      return (
        <Text
          key={section.content}
          style={[
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            styles[section.style] || section.style,
          ]}
        >
          {section.content}
        </Text>
      );
    case ContentTypes.Image:
      return (
        <Image
          key={section.src}
          src={section.src}
          style={[
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            section.style,
          ]}
        />
      );
    case ContentTypes.Container:
      return (
        <View
          key={ContentTypes.Container}
          style={[
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            section.style,
          ]}
        >
          {section.sections.map((subSection: Section) =>
            renderSection(subSection, styles),
          )}
        </View>
      );
    case ContentTypes.Footer:
    case ContentTypes.Header:
      return (
        <Text
          key={section.type}
          style={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            styles[section.style] || section.style
          }
        >
          {section.content}
        </Text>
      );
    default:
      return null;
  }
};

const renderPage = (page: DocumentPage, index: number) => {
  const styles = page.styles || {};
  const stylesMap = Object.keys(styles).reduce((map, key) => {
    if (!(key in styles)) {
      return styles;
    }

    return {
      ...map,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      [key]: StyleSheet.create(styles[key]),
    };
  }, {});

  return (
    <Page
      key={index}
      size={(page.pageSize || 'A4') as PageSize}
      style={[
        {
          padding: (page.margin || [20, 20, 20, 20]).join(' '),
        },
      ]}
    >
      {(page.sections || []).map((section) =>
        renderSection(section, stylesMap),
      )}
    </Page>
  );
};

const convertDataToPDFComponent = (template: iPDFData) => {
  return (
    <Document>
      {template.pages.map((page, index) => renderPage(page, index))}
    </Document>
  );
};

const renderContentEditor = (
  section: Section,
  onChange: (newContent: string, path: number[]) => void,
  pIndex: number,
  currentPath: number[],
  selectedContainerPath: number[],
  setSelectedContainerPath: (path: number[]) => void,
): React.ReactNode => {
  const renderSectionContent = () => {
    switch (section.type) {
      case ContentTypes.Text:
        return (
          <TextField
            value={section.content}
            onChange={(e) =>
              onChange(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                e.target.value || '',
                currentPath,
              )
            }
          />
        );

      case ContentTypes.Image:
        return <img src={section.src} />;

      case ContentTypes.Header:
      case ContentTypes.Footer:
        return (
          <TextField
            type="text"
            value={section.content}
            onChange={(e) =>
              onChange(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                e.target.value || '',
                currentPath,
              )
            }
          />
        );

      case ContentTypes.Container:
        return (
          <div className="container-content">
            {section.sections.map((subSection, subIndex) => {
              // Create path including the index of the text element
              const newPath = [...currentPath, subIndex];
              return renderContentEditor(
                subSection,
                onChange,
                pIndex,
                newPath,
                selectedContainerPath,
                setSelectedContainerPath,
              );
            })}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div
      key={`${pIndex}-${currentPath.join('-')}`}
      data-type={section.type || ''}
      className={
        selectedContainerPath.length > 0 &&
        currentPath[currentPath.length - 1] ===
          selectedContainerPath[selectedContainerPath.length - 1]
          ? 'selected-container'
          : ''
      }
      onClick={(e) => {
        e.stopPropagation();
        if (section.type === ContentTypes.Container) {
          setSelectedContainerPath(currentPath);
        }
      }}
    >
      {renderSectionContent()}
    </div>
  );
};

const PDFEditorHelper = { convertDataToPDFComponent, renderContentEditor };

export default PDFEditorHelper;
