import iOrderPageTemplate from '../../types/orderPageTemplate/iOrderPageTemplate';
import styled from 'styled-components';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React, { useState } from 'react';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '../frameWork/DropdownMenu';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import Flex from '../frameWork/Flex';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import OrderPageTemplateService from '../../services/orderPageTemplate/OrderPageTemplateService';
import { useNavigate } from 'react-router-dom';
import { URL_SETTINGS_ORDER_PAGE_TEMPLATE } from '../../helpers/UrlMap';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import EntityEditPopupBtn from '../form/EntityEditPopupBtn';
import EntityNames from '../../helpers/EntityNames';
import PDFEditor from '../pdf/PDFEditor';
import iPDFData from '../pdf/iPDFData';

const Wrapper = styled.div``;

export type iOrderPageTemplateDetails = iComponentWithPageHeader & {
  orderPageTemplate: iOrderPageTemplate;
  className?: string;
  testId?: string;
  onSaved?: (saved: iOrderPageTemplate) => void;
  allowEdit?: boolean;
  allowDelete?: boolean;
};
const OrderPageTemplateDetailsPanel = ({
  orderPageTemplate,
  className,
  testId,
  headerProps,
  allowDelete,
  allowEdit,
  onSaved,
}: iOrderPageTemplateDetails) => {
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const componentName = 'OrderPageTemplateDetails';
  const testIdStr = `${testId || ''}-OrderPageTemplateDetails`;
  const navigate = useNavigate();

  const getDeletePopupElements = () => {
    if (allowDelete !== true) {
      return {};
    }
    return {
      dropdownItem: (
        <DropdownItem
          testId={'del-dropdown-item'}
          onClick={() => {
            setShowDeletePopup(true);
          }}
        >
          Delete
        </DropdownItem>
      ),
      popupBtn: (
        <DeleteConfirmPopupBtn
          titleId={`delete-${orderPageTemplate.id}`}
          forceShown={showDeletePopup}
          renderBtn={() => null}
          deleteFnc={() =>
            OrderPageTemplateService.deactivate(orderPageTemplate.id).then(
              (resp) => {
                Toaster.showToast(
                  `${orderPageTemplate.name} deleted`,
                  TOAST_TYPE_SUCCESS,
                );
                return resp;
              },
            )
          }
          onDeleted={() => navigate(URL_SETTINGS_ORDER_PAGE_TEMPLATE)}
          onClose={(setModelShowing) => {
            setShowDeletePopup(false);
            setModelShowing(false);
          }}
          onCancel={() => {
            setShowDeletePopup(false);
          }}
        />
      ),
    };
  };

  const getEditPopupElements = () => {
    if (allowEdit !== true) {
      return {};
    }
    return {
      dropdownItem: (
        <DropdownItem
          testId={'edit-dropdown-item'}
          onClick={() => {
            setShowEditPopup(true);
          }}
        >
          Edit
        </DropdownItem>
      ),
      popupBtn: (
        <EntityEditPopupBtn<iOrderPageTemplate>
          renderBtn={() => null}
          entity={orderPageTemplate}
          entityName={EntityNames.OrderPageTemplate}
          forceShown={showEditPopup}
          onClose={(setModelShowing) => {
            setShowEditPopup(false);
            setModelShowing(false);
          }}
          onCancel={() => {
            setShowEditPopup(false);
          }}
          updateFn={(id, data) => OrderPageTemplateService.update(id, data)}
          onSaved={onSaved}
          getFormFields={({ entity, isDisabled }) => {
            return [
              {
                fieldName: 'name',
                label: 'Name',
                isDisabled,
                isRequired: true,
                value: entity?.name || '',
                testId: 'name',
              },
              {
                fieldName: 'description',
                label: 'Description',
                isDisabled,
                value: entity?.description || '',
                testId: 'description',
              },
            ];
          }}
        />
      ),
    };
  };

  const { dropdownItem: deleteDropDownItem, popupBtn: deletePopupBtn } =
    getDeletePopupElements();
  const { dropdownItem: editDropDownItem, popupBtn: editPopupBtn } =
    getEditPopupElements();
  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      <ComponentWithPageHeader
        headerProps={{
          ...headerProps,
          actions: (
            <Flex className={'gap-1 align-items-center'}>
              {(deleteDropDownItem || editDropDownItem) && (
                <>
                  <DropdownMenu
                    placement={'bottom-end'}
                    shouldRenderToParent
                    trigger={({ triggerRef, ...props }) => {
                      return (
                        <IconButton
                          {...props}
                          testId={'opts-btn'}
                          icon={Icons.MoreIcon}
                          ref={triggerRef}
                          label={''}
                          isSelected={false}
                        />
                      );
                    }}
                  >
                    <DropdownItemGroup>
                      {editDropDownItem}
                      {deleteDropDownItem}
                    </DropdownItemGroup>
                  </DropdownMenu>
                  {editPopupBtn}
                  {deletePopupBtn}
                </>
              )}
            </Flex>
          ),
        }}
      >
        <PDFEditor
          data={orderPageTemplate.templateData as iPDFData}
          onChanged={async (data) => {
            OrderPageTemplateService.update(orderPageTemplate.id, {
              templateData: data,
            });
          }}
        />
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default OrderPageTemplateDetailsPanel;
