export enum PageSizes {
  A4 = 'A4',
  Letter = 'Letter',
  A3 = 'A3',
  A5 = 'A5',
}

type PageSize = PageSizes.A3 | PageSizes.A4 | PageSizes.Letter | PageSizes.A5;

export enum PageOrientations {
  portrait = 'portrait',
  landscape = 'landscape',
}
export const DEFAULT_PAGE_MARGIN = 20;
type PageOrientation = PageOrientations.portrait | PageOrientations.landscape;
type Margin = [number, number, number, number]; // [left, top, right, bottom]
type Color = string;
type FontWeight =
  | 'normal'
  | 'bold'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';
type TextAlign = 'left' | 'center' | 'right' | 'justify';

export interface Style {
  fontSize?: number;
  color?: Color;
  fontWeight?: FontWeight;
  textAlign?: TextAlign;
  lineHeight?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  width?: number | string;
  height?: number | string;
  borderWidth?: number;
  borderColor?: Color;
  padding?: number;
  flexDirection?: 'row' | 'column';
  flex?: number;
}

export enum ContentTypes {
  Text = 'text',
  Image = 'image',
  Header = 'header',
  Footer = 'footer',
  Container = 'container',
}

interface BaseSection {
  id: string;
  type: ContentTypes;
}

export interface TextSection extends BaseSection {
  type: ContentTypes.Text;
  content: string;
  style?: Style;
}

export interface ImageSection extends BaseSection {
  type: ContentTypes.Image;
  src: string;
  style?: Style;
}

export interface HeaderFooterSection extends BaseSection {
  type: ContentTypes.Header | ContentTypes.Footer;
  content: string;
  style?: Style;
}

export interface ContainerSection extends BaseSection {
  type: ContentTypes.Container;
  style?: Style;
  sections: Section[];
}

export type Section =
  | TextSection
  | ImageSection
  | HeaderFooterSection
  | ContainerSection;

export interface DocumentStyles {
  header?: Style;
  body?: Style;
  footer?: Style;
  [key: string]: Style | undefined;
}

export interface DocumentPage {
  pageSize?: PageSize;
  pageOrientation?: PageOrientation;
  margin?: Margin;
  styles?: DocumentStyles;
  sections: Section[];
}

export interface iPDFData {
  pages: DocumentPage[];
}
export const defaultPage: DocumentPage = {
  pageSize: PageSizes.A4,
  pageOrientation: PageOrientations.portrait,
  margin: [
    DEFAULT_PAGE_MARGIN,
    DEFAULT_PAGE_MARGIN,
    DEFAULT_PAGE_MARGIN,
    DEFAULT_PAGE_MARGIN,
  ],
  sections: [],
};
export const defaultPDFData: iPDFData = {
  pages: [defaultPage],
};

export default iPDFData;
