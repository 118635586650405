import moment from 'moment';
import _ from 'lodash';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../../../../helpers/DynamicTableHelper';
import EntityNames from '../../../../../helpers/EntityNames';
import UserHelper from '../../../../../helpers/UserHelper';
import BuildPlanChangeService from '../../../../../services/plan/BuildPlanChangeService';
import { AssetTypes } from '../../../../../types/asset/iAsset';
import iBuildPlanChange from '../../../../../types/plan/iBuildPlanChange';
import iUser from '../../../../../types/system/iUser';
import ComponentWithPageHeader from '../../../../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../../../../common/PageTitleWithCreateBtn';
import { DatePicker, formatDate } from '../../../../frameWork/DateTimePicker';
import Icons from '../../../../frameWork/Icons';
import { iOptionWithData } from '../../../../frameWork/Select';
import Toggle from '../../../../frameWork/Toggle';
import Flex from '../../../../frameWork/Flex';
import useListCrudHook from '../../../../hooks/useListCrudHook/useListCrudHook';
import UserSelector from '../../../../user/UserSelector';
import TextArea from '../../../../frameWork/TextArea';
import Heading from '../../../../frameWork/Heading';
import iBuildPlan from '../../../../../types/plan/iBuildPlan';
import { SelectiveColKeys } from '../../../../../services/LocalStorageService';
import Toaster from '../../../../common/Toaster';
import { iEntityFormField, iUseAsForm } from '../../../../form/EntityEditPanel';
import PlansAttachmentWrapper from './PlansAttachmentWrapper';
import AttachmentService from '../../../../../services/attachment/AttachmentService';
import AttachmentsPopupBtn from '../../../../asset/AttachmentsPopupBtn';

type iBuildDetailsPlanChanges = {
  buildPlan: iBuildPlan;
  isDisabled?: boolean;
  allowEdit: boolean;
  allowDelete: boolean;
  onDeleted?: (deleted: iBuildPlanChange) => void;
  onSaved?: (saved: iBuildPlanChange, isCreated: boolean) => void;
};

const BuildDetailsPlansChanges = ({
  buildPlan,
  allowEdit,
  allowDelete,
  isDisabled,
  onDeleted,
  onSaved,
}: iBuildDetailsPlanChanges) => {
  const ComponentName = 'BuildDetailsPlansChanges';
  const testIdStr = `${ComponentName}-testID`;
  const {
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    onRefreshOnCurrentPage,
  } = useListCrudHook<iBuildPlanChange>({
    sort: `createdAt:DESC`,
    getFn: (params) =>
      BuildPlanChangeService.getAll({
        where: JSON.stringify({
          buildPlanId: buildPlan.id,
          isActive: true,
        }),
        ...(params?.filter || {}),
        include: 'CreatedBy,UpdatedBy,RequestedBy,Revision,BuildPlan.Revision',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const postUploadPlan = async (
    data: iBuildPlanChange,
    isReceived: boolean,
  ) => {
    if (data.revisionId || !isReceived) return;
    BuildPlanChangeService.nextRevision(data.id, data)
      .then(() => {
        onRefreshOnCurrentPage();
        onSaved && onSaved(data, false);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      });
  };

  const getEditBtn = (buildPlanChange?: iBuildPlanChange) => {
    return renderEntityEditPopBtn<iBuildPlanChange>({
      editingEntity: buildPlanChange,
      entityName: 'BuildPlanChange',
      isDisabled,
      onSaved,
      createFn: async (data) => {
        const created = await BuildPlanChangeService.create({
          buildPlanId: buildPlan.id,
          ...data,
        });
        if (!_.get(data, 'sentPlans')) return created;
        const attachmentIds: Array<string> = JSON.parse(
          _.get(data, 'sentPlans'),
        );
        // update the attachments' entityId
        if (attachmentIds.length > 0) {
          await Promise.all(
            attachmentIds.map((attachmentId) =>
              AttachmentService.update(attachmentId, {
                entityId: created.id,
                entityName: EntityNames.BuildPlanChange,
              }),
            ),
          );
        }
        return created;
      },
      updateFn: (id, data) => BuildPlanChangeService.update(id, data),
      renderEditBtn: ({ entity, onClick }) =>
        getCreateIconBtn({
          onClick: onClick,
          appearance: 'subtle',
          isTooltipDisabled: false,
          testId: `edit-btn-${entity.id}`,
          label: 'update',
          icon: () => <Icons.EditFilledIcon size="small" label={''} />,
        }),
      getFormFields: ({ entity, isDisabled }): iEntityFormField[] => [
        {
          fieldName: 'requestedById',
          label: 'Requested By',
          isDisabled,
          isRequired: true,
          value: entity?.requestedById || '',
          testId: 'BuildPlanChange-requestedById',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <UserSelector
                {...props}
                {...errorProps}
                onChange={(option: iOptionWithData<iUser> | null) => {
                  useAsForm.onFieldChange(fieldName, option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'requestedDate',
          label: 'Requested Date',
          isDisabled,
          isRequired: true,
          value: entity?.requestedDate || '',
          testId: 'buildPlanChange-requestedDate',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <DatePicker
                {...props}
                {...errorProps}
                value={props.value as string}
                onChange={(selected) => {
                  useAsForm.onFieldChange(
                    fieldName,
                    `${selected || ''}`.trim() === ''
                      ? null
                      : moment(`${selected || ''}`.trim()).toISOString(),
                  );
                }}
              />
            );
          },
        },
        {
          fieldName: 'receivedDate',
          label: 'Date Received',
          isDisabled,
          value: entity?.receivedDate || '',
          testId: 'buildPlanChange-receivedDate',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <DatePicker
                {...props}
                {...errorProps}
                value={props.value as string}
                onChange={(selected) => {
                  useAsForm.onFieldChange(
                    fieldName,
                    `${selected || ''}`.trim() === ''
                      ? null
                      : moment(`${selected || ''}`.trim()).toISOString(),
                  );
                }}
              />
            );
          },
        },
        {
          fieldName: 'comments',
          label: 'Comments',
          isDisabled,
          value: entity?.comments || '',
          testId: 'buildPlanChange-comments',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <TextArea
                {...props}
                {...errorProps}
                value={props.value as string | undefined}
                onChange={(event) => {
                  useAsForm.onFieldChange(fieldName, event.target.value);
                }}
              />
            );
          },
        },
        ...(!buildPlanChange
          ? [
              {
                fieldName: 'sentPlans',
                label: 'Sent Plans',
                isDisabled,
                value: _.get(entity, 'sentPlans') || JSON.stringify([]),
                testId: 'buildPlanChange-sentPlans',
                renderComponent: (
                  fProps: Omit<iEntityFormField, 'renderComponent' | 'isValid'>,
                  useAsForm: iUseAsForm,
                ) => {
                  return (
                    <PlansAttachmentWrapper
                      fProps={fProps}
                      useAsForm={useAsForm}
                      selectiveColumnKey={
                        `${ComponentName}-${testIdStr}` as SelectiveColKeys
                      }
                    />
                  );
                },
              },
            ]
          : []),
        {
          fieldName: 'isChangesCorrect',
          label: 'Changes Correct',
          isDisabled,
          value: !!(entity?.isChangesCorrect || ''),
          testId: 'buildPlanChange-isChangesCorrect',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <Toggle
                {...props}
                {...errorProps}
                separatedLines
                value={'isChangesCorrect'}
                isChecked={!!entity?.isChangesCorrect}
                onChange={(event) => {
                  useAsForm.onFieldChange(fieldName, event.target.checked);
                }}
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iBuildPlanChange>[] => [
    {
      key: 'requestedBy',
      header: 'Requested By',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) =>
        UserHelper.getFullName(data.RequestedBy),
    },
    {
      key: 'requestedDate',
      header: 'Requested Date',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) =>
        formatDate(data.requestedDate, 'DD/MM/YYYY'),
    },
    {
      key: 'receivedDate',
      header: 'Date Received',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) =>
        formatDate(data.receivedDate, 'DD/MM/YYYY'),
    },
    {
      key: 'comments',
      header: 'Comments',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) =>
        `${data.comments || ''}`.trim(),
    },
    {
      key: 'sentPlans',
      header: 'Sent Plans',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) => (
        <AttachmentsPopupBtn
          entityId={data.id}
          entityName={EntityNames.BuildPlanChange}
          types={[AssetTypes.BUILD_PLAN_CHANGE_SENT_PLANS]}
          postUploadPlan={() => postUploadPlan(data, false)}
          title="Sent Plans"
          testId="sent-plans"
        />
      ),
    },
    {
      key: 'isChangesCorrect',
      header: 'Changes Correct',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) =>
        data.isChangesCorrect && (
          <Icons.HipchatMediaAttachmentCountIcon
            testId={'colorful-dot-icon'}
            primaryColor={'#006644'}
            label={'dot'}
            size={'medium'}
          />
        ),
    },
    {
      key: 'revisionId',
      header: 'Revision',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) =>
        data.Revision?.name || '',
    },
    {
      key: 'receivedPlans',
      header: 'Received Plans',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) => (
        <AttachmentsPopupBtn
          entityId={data.id}
          entityName={EntityNames.BuildPlanChange}
          types={[AssetTypes.BUILD_PLAN_RECEIVED_PLANS]}
          postUploadPlan={() => postUploadPlan(data, true)}
          onClose={() => {
            onSaved && onSaved(data, false);
          }}
          testId="received-plans"
          title="Received Plans"
        />
      ),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildPlanChange>(),
    {
      key: 'btns',
      header: '',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildPlanChange>) => {
        return (
          <Flex className={'justify-content-end gap-025'}>
            {allowEdit && getEditBtn(data)}
            {allowDelete &&
            (!data.revisionId ||
              (data.Revision?.sort || 0) >=
                (data.BuildPlan?.Revision?.sort || 0)) ? ( // This checks if it's the latest revision
              renderDeleteBtn({
                deletingModel: data,
                deleteFnc: async () =>
                  BuildPlanChangeService.deactivate(data.id),
                getDisplayName: (item) => item.RequestedBy?.firstName || '',
                onDeleted: (deleted: iBuildPlanChange) => {
                  onDeleted && onDeleted(deleted);
                },
              })
            ) : (
              <div />
            )}
          </Flex>
        );
      },
    },
  ];
  return (
    <ComponentWithPageHeader
      headerProps={{
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={<Heading size={'small'}>Plan Changes</Heading>}
          />
        ),
      }}
    >
      {renderDataTable({
        selectiveColumnKey: SelectiveColKeys.BUILD_PLAN_CHANGE_LIST,
        columns: getColumns(),
        tblProps: {
          testId: 'BuildDetailsPlansChanges-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildDetailsPlansChanges;
