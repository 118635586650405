import styled from 'styled-components';
import tokens from './Tokens';

const Flex = styled.div`
  display: flex;
  &.space-top {
    margin-top: ${tokens('space.100', '0.5rem')};
  }
  &.space-below {
    margin-bottom: ${tokens('space.100', '0.5rem')};
  }
`;

export const InlineFlex = styled.div`
  display: inline-flex;
`;

export default Flex;
