import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import DeveloperGuidesService from '../../services/developerGuides/DeveloperGuidesService';
import iDeveloperGuides from '../../types/developerGuide/iDeveloperGuide';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import { iOptionWithData } from '../frameWork/Select';
import EstateSelector from '../estate/EstateSelector';
import iEstate from '../../types/system/iEstate';
import AttributeSelector from '../attribute/AttributeSelector';
import iAttribute from '../../types/attribute/iAttribute';
import DeveloperGuideCategorySelector from '../developerGuideCategory/DeveloperGuideCaergorySelector';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import { useSearchParams } from 'react-router-dom';

type iDeveloperGuidesList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const DeveloperGuidesList = ({
  allowDelete,
  headerProps,
}: iDeveloperGuidesList) => {
  const [searchParams] = useSearchParams();
  const estateId = searchParams.get('estateId');
  const {
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    onSetFilter,
  } = useListCrudHook<iDeveloperGuides>({
    sort: `createdAt:DESC`,
    filter: estateId ? { estateId } : undefined,
    getFn: (params) =>
      DeveloperGuidesService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,Estate,Attribute,Category',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (DeveloperGuides?: iDeveloperGuides) => {
    return renderEntityEditPopBtn<iDeveloperGuides>({
      editingEntity: DeveloperGuides,
      entityName: 'DeveloperGuides',
      createFn: (data) => DeveloperGuidesService.create(data),
      updateFn: (id, data) => DeveloperGuidesService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity.Estate?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'estateId',
          label: 'Estate',
          isDisabled,
          isRequired: true,
          value: entity?.estateId || '',
          testId: 'DeveloperGuides-estate',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <EstateSelector
                {...props}
                {...errorProps}
                testId={'estate-selector'}
                onChange={(option: iOptionWithData<iEstate> | null) => {
                  useAsForm.onFieldChange('estateId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'attributeId',
          label: 'Attribute',
          isDisabled,
          value: entity?.attributeId || '',
          testId: 'DeveloperGuide-attribute',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <AttributeSelector
                {...props}
                {...errorProps}
                testId={'attribute-selector'}
                onChange={(option: iOptionWithData<iAttribute> | null) => {
                  useAsForm.onFieldChange('attributeId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'category',
          label: 'Category',
          isDisabled,
          value: entity?.categoryId || '',
          testId: 'DeveloperGuides-category',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <DeveloperGuideCategorySelector
                {...props}
                {...errorProps}
                testId={'category-selector'}
                onChange={(
                  option: iOptionWithData<iDeveloperGuideCategory> | null,
                ) => {
                  useAsForm.onFieldChange('categoryId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'validationRules',
          label: 'ValidationRules',
          isDisabled,
          value: entity?.validationRules || '',
          testId: 'DeveloperGuides-validationrules',
        },
        {
          fieldName: 'sort',
          label: 'Sort',
          isDisabled,
          value: entity?.sort || '',
          testId: 'DeveloperGuides-sort',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iDeveloperGuides>[] => [
    {
      key: 'estate',
      header: 'Estate',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iDeveloperGuides>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'attribute',
      header: 'Attribute',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iDeveloperGuides>) => {
        return `${data.Attribute?.name || ''}`;
      },
    },
    {
      key: 'category',
      header: 'Category',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iDeveloperGuides>) => {
        return `${data.Category?.name || ''}`;
      },
    },
    {
      key: 'validationRules',
      header: 'Validation Rules',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iDeveloperGuides>) => {
        return `${data?.validationRules || ''}`;
      },
    },
    {
      key: 'sort',
      header: 'Sort',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iDeveloperGuides>) => {
        return `${data?.sort || ''}`;
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iDeveloperGuides>(
      true,
      true,
    ),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iDeveloperGuides>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      DeveloperGuidesService.deactivate(data.id),
                    getDisplayName: (DeveloperGuides) =>
                      DeveloperGuides.estateId,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={'developerguides-list-search'}
            onSearch={(searchText: string) => {
              onSetFilter({
                [OP_OR]: [{ name: { [OP_LIKE]: `%${searchText}%` } }],
              });
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.DEVELOPER_GUIDES,
        tblProps: {
          testId: 'developerguides-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};
export default DeveloperGuidesList;
